import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const materialSelectionMenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    materialMenuRoomContainer: {
      maxHeight: '80%',
      boxShadow: 'none !important' // This removes the extra lines around accordions
    },
    materialMenu: {
      width: '100%',
      paddingBottom: theme.spacing(1),
      backgroundColor: theme.palette.accordionBackground.default,
      borderRadius: '10px',
      border: 'none',
    },
    materialMenuHeading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: 'auto',
      flexShrink: 0,
      margin: theme.spacing(2),
      marginBottom: 0,
      paddingTop: theme.spacing(2)
    },
    materialMenuSecondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
      margin: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginTop: 0
    },
      customButton: {
        background: `${theme.palette.buttons.default} !important`,
        color: theme.palette.primary.contrastText,
          '&:hover': {
            background: 'white !important',
              color: 'black'
          }
      },
    returnButton: {
      color: theme.palette.buttons.paper
    },
      checkMark: {
          fontSize: '2rem', //.5
          position: 'relative',
          top: '50%',
          marginTop: '-1rem', //Half of the icon size .25
          //marginRight: '25%',
          color: theme.palette.checkIcons.default
      } 
  }),
);

export default materialSelectionMenuStyles