
import { Paper, Button, Grid, SvgIconProps } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { StylesStateContext } from '../../../store/stylesState/StylesStateProvider'
import stylesAndMaterialsBarStyles from './StylesAndMaterialsBar.styles'

interface StylesAndMaterialsBarSection {
  id: number
  caption: string
  isVisible?: boolean
  icon?: React.ReactElement<SvgIconProps>
}

interface StylesAndMaterialsBarProps {
  tabs: StylesAndMaterialsBarSection[]
  onClick: (tabId: number) => void
  // If this is defined, phasesBar will use as icons the images assigned from CJ1 which are stored in this project (/public/Icons)
  // In the future, phases will be created from CC, and instead of images, icons will be used.
  iconsFolderUrl?: string
}

export const StylesAndMaterialsBar: React.FC<StylesAndMaterialsBarProps> = ({
  tabs,
  onClick,
  ...rest
}: StylesAndMaterialsBarProps) => {
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const { stylesState } = useContext(StylesStateContext)

  const classes = stylesAndMaterialsBarStyles()

  const showBothTabs = tabs.filter(tab => { return tab.isVisible === true}).length > 1 ? true : false

  useEffect(() => {
    if(stylesState.activeTab !== null)
      setSelectedTab(stylesState.activeTab)
  }, [stylesState.activeTab])

  return (
    tabs.length ?
      <div className={classes.OptionsBar}>
        <Grid item container xs={12} md={12} className={classes.tabsContainer}>
          {tabs?.map((tab, index) => {
            return (
              tab.isVisible ?
                <Grid item xs={12} md={showBothTabs ? 6 : 12} key={index}>
                  <Button
                    key={index}
                    variant="contained"
                    color="primary"
                    className={
                      selectedTab === index
                        ? `${classes.root} ${classes.tab} ${classes.selectedTab}`
                        : `${classes.root} ${classes.tab}`
                    }
                    startIcon={tab.icon}
                    onClick={() => {
                      setSelectedTab(index)
                      onClick(tab.id)
                    }}
                  >{tab.caption}
                  </Button>
                </Grid>
              : null
            )
          })}
        </Grid>
      </div> : null
  )
}