import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface props{
  isCardButton?: boolean
}

const useStyles = ({isCardButton}:props) =>
  makeStyles((theme: Theme) => {
    return createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        border: '2px solid #EAECF0',
        //boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
        borderRadius: '13px',
        cursor: isCardButton ? 'pointer' : undefined,
        
        //minHeight: '180px'
      },
      selectionImage: {
        height: `calc(180px * 0.66666)`,
        margin: '0',
        justifyContent: 'center',
        color: 'grey',
        overflow: 'hidden',
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        objectFit: 'cover'
      },
      image: {
        objectFit: 'cover',
        width: '100%'
      },
      selectionContent: {
        display: 'flex',
        flexDirection: 'row',
        height: `calc(180px * 0.33333)`,

        padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`,

        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        
        alignItems: 'center',
        justifyContent: 'center'
      },
      selectButton: {
        width: '100% !important',
        height: 'fit-content',
        padding: `${theme.spacing(1)}px 0`,
        margin: `${theme.spacing(1)}px 0`,
        marginLeft: theme.spacing(1),

        background: 'white',
        border: '1px solid #EAECF0',
        boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
        borderRadius: '8px',
        cursor: 'pointer',
        '&:hover': {
          filter: 'brightness(0.9)',
        }
      },
      hover: {
        background: 'inherit',
        filter: 'brightness(0.9)',
        
      },
      replacement: {
        display: 'flex',
        minWidth: '120px',
        width: '100% !important',
        height: '50px',

        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',

        background: theme.palette.primary.main,
        border: '1px solid #EAECF0',
        boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
        borderRadius: '8px'
      },
      replacementText: {
        fontWeight: 'bold',
        marginLeft: '6px',
        marginRight: '6px',
        color: 'white'
      },
      captionContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'left'
      },
      captionText: {
        fontWeight: 'bold',
      },
      selectedText: {
        color: theme.palette.text.hint,
      },
      selectedIcon: {
        margin: '20px',

        background: theme.palette.checkIcons.default,//theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: '100%',
        padding: '5px'
      },
      selectedCard:{
        border: `3px solid ${theme.palette.buttons.default}`,
      },
      selectedButton:{
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.checkIcons.default
      },
      subSelection: {
        backgroundColor: theme.palette.styleCard.default,
        padding: theme.spacing(1),
      },
      subSelectionButton: {
        width: '100% !important',
        height: 'fit-content',
        padding: `${theme.spacing(1)}px 0`,
        margin: `${theme.spacing(1)}px 0`,
        //marginLeft: theme.spacing(1),

        background: 'white',
        border: '1px solid #EAECF0',
        boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
        borderRadius: '8px',
        cursor: 'pointer'
      },
      subSelectionHeader: {
        color: theme.palette.text.secondary,
      }
    });
  });

export default useStyles;
