import {Button, CircularProgress, Paper, Typography} from '@material-ui/core';
import React, {useContext, useState} from 'react';
import {t} from '../../../utils';
import {StylesState, StylesStateAction} from '../../../store/stylesState/interfaces';
import {Material, Room} from '../../../axios/instances/interfaces';
import useMaterialsFetcher from "../../../hooks/useMaterialsFetcher"
import useRoomsFetcher from "../../../hooks/useRoomsFetcher";
import useEventLogger from '../../../analytics/useEventLogger';
import { useMaterialSelect } from '../../../hooks/useMaterialSelect';
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import RoomAccordion from './RoomAccordion';
import materialSelectionMenuStyles from './MaterialSelectionMenu.styles';
import ProductButton from "../../common/ProductButton";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import {StylesStateContext} from "../../../store/stylesState/StylesStateProvider";
import {GlobalStateContext} from "../../../store/globalState/GlobalStateProvider";
import ProductSelect from "../../common/ProductSelect/ProductSelect";

interface MaterialSelectionMenuProps {
  stylesState: StylesState;
}

export enum MENULEVEL { MAIN = 0, BUNDLE_LEVEL = 1, MATERIAL_LEVEL = 2}

/**
 * Primary UI component for user interaction
 */
export const MaterialSelectionMenu: React.FC<MaterialSelectionMenuProps> = ({
  stylesState,
}: MaterialSelectionMenuProps) => {
  const {
    openRoom,
    fetchingRooms,
    rooms,
    bundles,
    selectedBundle,
  } = stylesState

  const [menuLevel, setMenuLevel] = useState(0);
  const {dispatch} = useContext(StylesStateContext);
  const {globalState} = useContext(GlobalStateContext);
  
  const apiSelectMaterial = useMaterialSelect();
  const { logActions } = useEventLogger();
  useMaterialsFetcher()
  useRoomsFetcher()

  const classes = materialSelectionMenuStyles();

  const handleMenuLevelChange = (level: number) => {
    setMenuLevel(level)
  }

  const handleSelection = (material: Material) => {
    if(!selectedBundle)
    {
      return;
    }
    
    if(selectedBundle.previewedMaterial?.id === material.id)
    {
      apiSelectMaterial?.(material, selectedBundle.id.toString());
      //logActions('select_material', 'Material ' + material.name + ' Selected', 'Material_1');
      dispatch({type: StylesStateAction.SELECT_MATERIAL, data: {bundleId: selectedBundle?.id, materialId: material.id}})
    }
    else{
      dispatch({type: StylesStateAction.PREVIEW_MATERIAL, data: {bundleId: selectedBundle?.id, materialId: material.id}})      
    }
    
  }
  
  const formatPrice = (material: Material) => {
    
    if(material.totalCost === "0.01")
    {
      return t("theming.askPrice")
    }
    
    return Intl.NumberFormat(globalState.tenantSettings.environmentLocale, {style: 'currency', currency: globalState.tenantSettings.currencyCode}).format(Number(material.totalCost))
  }
  
  const isPreviewing = (material: Material) =>
  {
    return selectedBundle?.previewedMaterial?.id === material.id;
  }
  
  const roomName = rooms?.filter(room => room.id === openRoom).map(room => room.name).toString();

  return (
    fetchingRooms ? <CircularProgress style={{marginLeft: '50%'}}/> : (
      <Paper className={classes.materialMenuRoomContainer}>
      {menuLevel === MENULEVEL.MAIN  && bundles ? 
      (
        <>
          <RoomAccordion room={null} bundles={bundles} isExpanded={openRoom === null} handleMenuLevelChange={handleMenuLevelChange} />
          {rooms ? 
            rooms.map((room: Room) => {
              const isExpanded = room.id === openRoom;
              return (
                room.name !== 'AUKKO' ? // Required as current CAD cannot handle empty spaces but they are made as rooms named AUKKO instead
                  <RoomAccordion key={room.id} {...{room, bundles, isExpanded, handleMenuLevelChange }}/> 
                  : null
              )
            })
          : null
        }
        </>
      ) 
      : 
      (
        <>
          <Button className={classes.returnButton} onClick={() => {
            handleMenuLevelChange(MENULEVEL.MAIN)
          }}
          size='large'
          startIcon={<ChevronLeft />}
          > 
            {t("theming.returnToBundle")} {roomName ? roomName : t('theming.generalAppearance')}
          </Button>
          <div className={classes.materialMenu}>
            <Typography className={classes.materialMenuHeading} >{selectedBundle?.name}</Typography> 
            <Typography className={classes.materialMenuSecondaryHeading} >{t('theming.selectRoomMaterials')}</Typography> 
            {selectedBundle ?
              selectedBundle.materialOptions.map((material) => {
                const isSelected = selectedBundle.selectedMaterial?.id === material.id;
                const previewing = isPreviewing(material);
                const bd = !isSelected && !previewing ? t('theming.preview') : !isSelected ? t('theming.choose') : t('theming.show')
                /**/
                return <ProductSelect key={material.id} 
                                      productName={material.name} 
                                      imageUrl={material.imageUrlSmall}
                                      onSelect={() => handleSelection(material)} 
                                      buttonDescription={bd}
                                      caption={formatPrice(material)}
                                      isSelected={isSelected}
                                      customButtonClass={previewing && !isSelected ? classes.customButton : undefined}
                                      showButton={(!isSelected) || (!previewing) }
                                      subCaption={isSelected ? t('theming.selected') : previewing ? t('theming.previewing') : undefined}
                />
                /**
                return <ProductButton key={material.id} 
                                      productName={material.name}
                                      productDescription={formatPrice(material)}
                                      productImageUrl={material.imageUrlSmall}
                                      onClicked={() => handleSelection(material)}
                                      icon={isSelected ? <CheckCircleRoundedIcon className={classes.checkMark}/> : undefined}
                                      adjective={isSelected ? t('theming.selected') : isPreviewing(material) ? t('theming.previewing') : undefined}
                />
                /**/
                //return <MaterialCard key={material.id} material={material} isSelected={isSelected} bundle={selectedBundle} />
              }
              ) : null
            }
          </div>
        </>
      )
    }
    </Paper>
    )
  );
};
